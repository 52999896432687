.repo-diff-tile {
    min-height: 300px;
    border-radius: 8px;
    padding: 32px;
    margin: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.repo-diff-tile span {
    display: block;
}

.repo-diff-tile--changes {
    background-color: red;
}

.repo-diff-tile--no-changes {
    background-color: green;
}
.repo-diff-tile__repo-name {
    font-size: 20px;
    margin-bottom: 16px;
    font-weight: bold;
}

.repo-diff-tile__changes-title {
    font-size: 18px;
}

.repo-diff-tile__diff-link {
 font-weight: bold;
}