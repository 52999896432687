.input__label {
    display: block;
    margin-bottom: 4px;
}

.input {
    background-color: white;
    border-radius: 8px;
    margin-bottom: 16px;
    padding: 12px;
    width: 100%;
    max-width: 500px;
    font-size: 16px;
}

.input__label--invalid {
    color: red;
}

.input__help-text {
    display: block;
}

.input__label svg {
    opacity: 0;
}

.input__label--invalid svg {
    opacity: 1;
}

.input__checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.input--checkbox {
    width: 18px;
    height: 18px;
    margin: 0;
    margin-right: 16px;
}

@media (min-height: 800px) {
    .input {
        margin-bottom: 32px;
    }

    .input--checkbox {
        margin-bottom: 0;
    }
}