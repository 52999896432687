.release-actions {
    display: flex;
    margin-bottom: 32px;
}

.release-actions button {
    margin-right: 16px;
}

.release-data {
    display: flex;
    margin-top: 32px;
}

.release-data > form {
    margin-left: 16px;
    flex-grow: 1;
}