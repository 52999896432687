.pr-list__item + .pr-list__item {
    margin-top: 16px
}

.pr-list__item a, .pr-list__item p {
    margin: 0;
}

.pr-list__item a {
    font-size: 20px;
}

.pr-list__info {
    margin: 0;
    opacity: 0.6;
}
