.release-notes__input {
    display: block;
    margin-top: 8px;
    width: 100%;
    height: 90vh;
    padding: 8px;
    font-size: 18px;
    /* background-color: black; */
    /* color: yellow; */
    max-width: unset;
}

.release-notes__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.release-notes__header label {
    font-weight: bold;
    font-size: 18px;
}