* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
}

h1 {
  font-size: 48px;
}
.error-text {
  background-color: rgb(171, 19, 19);
  color: white;
  min-height: 100px;
  width: 100%;
  padding: 16px;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.error-text span {
  font-size: 20px;
  font-weight: bold;
}

.error-text--show {
  opacity: 1;
}

.page-container {
  width: 100%;
  padding: 8px;
  max-width: 1200px;
  margin: auto
}

.button {
  border: 4px solid black;
  color: white;
  background-color: black;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 18px;
  font-weight: bold;
  transition: background-color 200ms ease-in, color 200ms ease-in-out;
}

.button--small {
  padding: 4px;
  font-size: 16px;
}
.button:hover, .button:focus {
  color: black;
  background-color: white;
}

.button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.nav {
  width: 100%;
  padding: 8px;
  max-width: 1200px;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav h1 {
  margin-right: 16px;
  font-size: 20px;
}